.modal {
  position: fixed;
  z-index: 3;
  height: 100%;
  width: 100vw;
  background-color: #00000099;
  padding-top: 2rem;
}

.modalIcon:hover {
  animation: rotation 0.4s 1;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
