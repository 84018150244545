form {
  display: flex;
  flex-flow: column wrap;
  padding: 5rem;
}
form .enabled {
  margin: 2.5rem auto 0 auto;
  width: 6rem;
  height: 3rem;

  background-color: rgba(58, 55, 55, 1);
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;

  font-size: 22px;
  padding: 5px;
  text-decoration: none;
}
form .enabled:hover {
  border: 2px solid rgba(58, 55, 55, 0.75);
  color: rgba(58, 55, 55, 1);
  background-color: #fff;
}
form .enabled:active {
  position: relative;
  top: 1px;
}
form .disabled {
  margin: 2.5rem auto 0 auto;
  width: 6rem;
  height: 3rem;
  background-color: rgba(58, 55, 55, 0.5);
  border-radius: 6px;
  border: 2px solid #dcdcdc;
  display: inline-block;
  cursor: not-allowed;
  color: #ffffff;

  font-size: 22px;
  padding: 5px;
  text-decoration: none;
}

label {
  font-family: "Karla", sans-serif;

  font-size: 1.25rem;
  margin-right: auto;
  margin-left: 10px;
}
input {
  font-family: "Karla", sans-serif;
  margin: 1rem;
  font-size: 1.25rem;
  padding: 5px;
  width: inherit;
  border: none;
  background-color: rgba(58, 55, 55, 0.5);
  border-bottom: solid 1px #ffffff60;
}
textarea {
  font-family: "Karla", sans-serif;
  resize: none;
  margin: 1rem;
  font-size: 1.25rem;
  letter-spacing: 0px;
  padding: 5px;
  width: inherit;
  border: none;
  background-color: rgba(58, 55, 55, 0.5);
  border-bottom: solid 1px #ffffff60;
}
.contact {
  width: inherit;
  color: rgba(58, 55, 55, 1);
  padding-top: 4rem;
  background-color: #ffffff;
}
.contact hr {
  background-image: linear-gradient(
    to right,
    transparent,
    #fffff999,
    transparent
  );
}
.form-name {
  display: flex;
  flex-flow: column wrap;
}
@media screen and (max-width: 700px) {
  form {
    padding: 1rem;
  }
  input {
    margin: 0.5rem;
    font-size: 1rem;
    padding: 0;
  }
}
