nav {
  position: sticky;
  top: 0;
  background-color: #36bed9;
  border-radius: 4px;
  color: white;
  box-shadow: 0 0 10px 1px #4b4c4d;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.burger {
  display: none;
  margin: 10px;
  order: -1;
}

.logo {
  margin: 20px;
}
.hero-img {
  min-height: 60vh;
  max-width: 100%;
  background-image: url("../../assets/cover.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-img section {
  backdrop-filter: blur(5px);
  min-height: inherit;
  background-color: rgba(58, 55, 55, 0.6);
  color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.header-img-center {
  display: inline-flex;
  background-color: rgb(54, 190, 217);
  padding: 0.5rem;
  border-radius: 50%;
}
.header-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
@media screen and (max-width: 700px) {
  .hero-img {
    min-height: 50.6vh;
    max-width: 100%;
    background-repeat: repeat;
    background-size: cover;
  }
  .hidden {
    display: none;
  }

  .display {
    z-index: 1;
    border-top: solid 1px white;
    top: 60px;
    width: 100vw;
    height: 50px;
    animation: popUp 0.3s linear 0s 1 normal forwards running;
    opacity: 0;
    position: sticky;
    box-shadow: 0 4px 5px 0 #4b4c4d90;
    color: #fff;
    background-color: #36bed9;
    margin-bottom: 1rem;
  }
  .display p {
    padding: 0;
  }
  .display ul {
    padding: 0;
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #36bed9;
  }
  .display li {
    display: inherit;
  }
  nav {
    box-shadow: 0 4px 5px 0 #4b4c4d90;
  }
  nav li {
    display: none;
  }
  .logo {
    order: 1;
  }
  .burger {
    order: -1;
    display: block;
  }
}
