footer {
  padding: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 16vh;
  color: #ffffff;
  background-color: #36bed9;
}
footer a:hover {
  color: rgba(58, 55, 55, 1);
}
footer li {
  margin-top: 1rem;
}
@media screen and (max-width: 700px) {
  footer {
    padding: 30px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 14vh;
    color: #fff;
    background-color: rgba(58, 55, 55, 1);
  }
  footer li {
    padding: 0.2rem;
    display: list-item;
  }
}
