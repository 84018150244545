html * {
  font-family: "Karla", sans-serif !important;
}

dl,
ol,
ul {
  margin-bottom: 0 !important;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

[id] {
  scroll-margin-top: 5ex;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Karla", sans-serif;
}
main {
  height: 100%;
  background-color: #fff;
}
ul {
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  list-style-type: none;
  display: flex;
}
li {
  padding: 0.5rem;
}
ul a {
  color: white;
  text-decoration: none;
}
ul a:hover {
  color: rgba(58, 55, 55, 1);
}
ul p {
  margin: 0;
  padding: 10px 5px 10px 0;
}

article img {
  max-width: 100%;
  min-height: auto;
}
h1 {
  display: block;
  font-size: 2em;
}
hr {
  z-index: 99999;
  height: 0.1rem;
  max-width: 100%;
  border: 0;
  background-image: linear-gradient(
    to right,
    transparent,
    #36bed9,
    transparent
  );
}
section {
  text-align: center;
}

.App {
  height: inherit;
}
.display li {
  display: none;
}
div.alt:hover {
  position: relative;
}
div.alt span {
  display: none;
}
div.alt:hover span {
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  background: rgba(58, 55, 55, 0.9);
  left: -27px;
  margin: 15px;
  width: 112px;
  position: absolute;
  bottom: 100px;
  text-decoration: none;
  border-radius: 20px;
  font-size: 15px;
}
.sr-only {
  display: none;
}
.carousel-control-prev-icon {
  border-radius: 12px;
  background-color: #00000099;
}

.carousel-control-next-icon {
  border-radius: 12px;
  background-color: #00000099;
}

@keyframes popUp {
  0% {
    z-index: 1;
    transform: translateY(-60px);
  }
  10% {
    z-index: 1;
  }
  30% {
    z-index: 1;
  }
  50% {
    z-index: 1;
  }
  100% {
    width: 100vw;
    z-index: 2;
    opacity: 1;
  }
}

/*Markdown CENTER*/
.center {
  text-align: center;
}
